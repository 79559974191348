import Footer from "./Footer";
import HeadBar from "./HeadBar";
import Header from "./Header";
import PropertyGallery from "./PropertyGallery";

export default function PropertyDetail(props){

    const db = props.db;
    return <div>
        <HeadBar />
        <Header />
        <PropertyGallery db = {db} />
        <Footer />
    </div>
}