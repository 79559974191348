import { Component } from "react";
import { Link } from "react-router-dom";
import ContactShort from "./ContactShort";
import Socials from "./Socials";

class Footer extends Component {
    render(){
        return (
            <div className="foot-body">
                <div className="foot-nav">
                    <a className="foot-nav__link" href="https://api.whatsapp.com/send/?phone=593983115804&text=Hola%2C+Te+escribo+desde+la+p%C3%A1gina%21&type=phone_number&app_absent=0" target="_blank">
                        <div className="foot-nav__link-content">
                            <img src="../img/wpicon.png" />
                        </div>
                    </a>
                    <a className="foot-nav__link" href="https://www.instagram.com/hauseqrealstate" target="_blank">
                        <div className="foot-nav__link-content">
                            <img src="../img/ig.png" />
                        </div>
                    </a>
                    <a className="foot-nav__link" href="https://www.facebook.com/hauseqrealstate" target="_blank">
                        <div className="foot-nav__link-content">
                            <img src="../img/fb.png" />
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}

export default Footer;