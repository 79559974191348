import { useEffect, useState } from "react";
import PropetyCard from "./PropertyCard";
import { NavLink } from "react-router-dom";

const MainGallery = (props) => {
  const [transactionType, setTransactionType] = useState("All");

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    console.log("transactionType");
    console.log(transactionType);
    console.log("props.db");
    console.log(props.db);

    setFilteredData(
      transactionType === "All"
        ? props.db
        : props.db.filter((item) => item.transaction === transactionType)
    );
  }, [transactionType, props.db]);

  return (
    <>
      <div className="filters-nav">
        <button className={
          transactionType === "Alquiler" ? "main-nav__link_unselected" : "main-nav__link"
        } onClick={()=> setTransactionType("Alquiler")}>
          Alquiler
        </button>

        <button className={
          transactionType === "Venta" ? "main-nav__link_unselected" : "main-nav__link"
        } onClick={()=> setTransactionType("Venta")}>
          Venta
        </button>
        
        <button className={
          transactionType === "All" ? "main-nav__link_unselected" : "main-nav__link"
        } onClick={()=> setTransactionType("All")}>
          Todos
        </button>
      </div>
      <div className="maingallery-container">
        {filteredData.map((item) => (
          <PropetyCard key={item._id} data={item} db={filteredData} />
        ))}
      </div>
    </>
  );
};

export default MainGallery;
