import HeadBar from "./HeadBar";
import Header from "./Header";
import Footer from "./Footer";

export default function About() {
  return (
    <div>
            <HeadBar />
            <Header />
            <div className="u-body">
    <section className="head-bck -lg -sm -xl -xs u-clearfix u-image u-shading u-section-1" src="" id="sec-aad8">
      <div className="u-clearfix u-sheet u-sheet-1 u-sheet-1-header">
        <div className="u-align-left-xs u-align-right-lg u-align-right-md u-align-right-sm u-align-right-xl u-container-style u-group u-group-1">
          <div className="u-container-layout u-valign-bottom u-container-layout-1">
            <h1 className="u-text u-title u-text-1">QUIÉNES SOMOS</h1>
            <p className="u-large-text u-text u-text-variant u-text-2">Somos una empresa ecuatoriana dedicada a la prestación de servicios y desarrollo de
proyectos, consultoría empresarial, en el ámbito inmobiliario dentro de la ciudad de Quito.
Contamos con una experiencia de más de 9 años, dentro de los cuales hemos demostrado
profesionalismo, disciplina, organización y seriedad.</p>
          </div>
        </div>
      </div>
    </section>
    <section className="u-clearfix u-section-2 about-cont1" id="sec-1b1d">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-container-style u-group u-group-1">
          <div className="u-container-layout u-valign-middle u-container-layout-1">
            <div className="mini-gallery">
            <div className="mini-gallery-item">
                <img src="https://images03.nicepage.com/c461c07a441a5d220e8feb1a/50ac257d00fc5a65a23a6402/1.jpg" alt="" />
              </div>
              <div className="mini-gallery-item">
                <img src="https://images03.nicepage.com/c461c07a441a5d220e8feb1a/ecae0d40d27a54499f983d9a/2.jpg" alt="" />
              </div>
              <div className="mini-gallery-item">
                <img src="https://images03.nicepage.com/c461c07a441a5d220e8feb1a/141ac024456b585dbfd32f82/Untitled1.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="u-clearfix u-section-3" id="carousel_dcaf">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-container-style u-group u-group-1">
          
        </div>
        <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-left u-container-style u-image u-layout-cell u-left-cell u-size-30 u-image-1">
                <div className="u-container-layout" src=""></div>
              </div>
              <div className="u-align-left u-container-style u-layout-cell u-palette-5-dark-3 u-right-cell u-size-30 u-layout-cell-2">
                <div className="u-container-layout u-valign-middle u-container-layout-3">
                  <h2 className="u-text u-text-palette-3-base u-text-2">Nuestra Misión</h2>
                  <p className="u-text u-text-palette-3-base u-text-3">Nuestro nombre es referente al éxito empresarial. Para nosotros, una declaración de misión
es una promesa vinculante y exigente.
Nuestra misión a largo plazo en las relaciones comerciales que realizamos, es posicionar y
fortalecer la marca HAUS EQ REAL ESTATE S.A.S, y al mismo tiempo preocuparnos por
garantizar que los clientes de toda la región, disfruten de la memorable experiencia que les
ofrece nuestra labor comercial.
Valoramos y cultivamos nuestras capacidades profesionales, siempre teniendo como
referentes la competencia, exclusividad y pasión. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    

    <section className="u-clearfix u-grey-5 u-section-7" id="sec-4ed6">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-left u-container-style u-expanded-width u-group u-group-1">
          <div className="u-container-layout u-valign-middle-xl u-valign-middle-xs u-container-layout-1">
            <h2 className="u-text u-text-1">Contamos con los mejores profesionales en cada área, para así juntos avanzar en el
desarrollo empresarial.
Somos innovadores, y eso nos hace líderes en nuestro campo.</h2>
          </div>
        </div>
      </div>
    </section>

    <section className="u-clearfix u-section-3" id="carousel_dcaf">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-container-style u-group u-group-1">
          
        </div>
        <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-left u-container-style u-image u-layout-cell u-left-cell u-size-30 u-image-1">
                <div className="u-container-layout" src=""></div>
              </div>
              <div className="u-align-left u-container-style u-layout-cell u-palette-5-dark-3 u-right-cell u-size-30 u-layout-cell-2">
                <div className="u-container-layout u-valign-middle u-container-layout-3">
                  <h2 className="u-text u-text-palette-3-base u-text-2">Nuestra Visión</h2>
                  <p className="u-text u-text-palette-3-base u-text-3">Vinculamos las aspiraciones de clientes inversionistas exigentes de todo el mundo a nuestra
diaria labor, ya sea en el ámbito privado o empresarial. Con total pasión, profesionalismo y
ética.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="u-clearfix u-grey-5 u-section-7" id="sec-4ed6">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-left u-container-style u-expanded-width u-group u-group-1">
          <div className="u-container-layout u-valign-middle-xl u-valign-middle-xs u-container-layout-1">
            <h2 className="u-text u-text-1">Nos tomamos en serio el cumplimiento de
los requisitos de los clientes más exigentes y ofrecemos un servicio de primera
clase. </h2>
          </div>
        </div>
      </div>
    </section>
  
</div>
            <Footer />
        </div>
  )
}
