import { useEffect, useState } from "react";

export default function PropertyHeader(props){
    return (
        <div className="property-header">

            <div className="property-header-headline">
                <div className={ props.cont.transaction === "ALQUILER" ? "transactionA transac" : "transactionV transac" }>
                    <p>{props.cont.transaction}</p>
                </div>

                <a href="https://api.whatsapp.com/send/?phone=593983115804&text=Hola%2C+Te+escribo+desde+la+p%C3%A1gina%21&type=phone_number&app_absent=0" className="sharerme">
                    <img src="/img/wpicon.png" />
                </a>
            </div>
            <div className="headline">
                <h1>{props.cont.title}</h1>
            </div>
            
            <div className="property-main-image">
                <img src={props.cont.image.url} />
            </div>
            
            <div className="property-descriptors">
                <p>{props.cont.description}</p>
            </div>
            
            <div className="property-detail-box">
                <div className="property-price">
                    <h2>USD {props.cont.price} </h2>
                </div>

                <div className="property-expensa">
                    {props.cont.aliquota !== "" && <h3>+{props.cont.aliquota} USD Alicuota</h3>}  
                </div> 
            </div>
        </div>
    );
}