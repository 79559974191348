import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSlider from 'react-awesome-slider';

export default function Slider() {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  return (
    <div>

        <AutoplaySlider
                play={true}
                bullets = {true}
                organicArrows = {false}
                cancelOnInteraction={false}
                interval={10000}
            >
                <div>
                    <img src="../img/carru1.jpg" alt="Haus EQ"/>
                </div>
                <div>
                    <img src="../img/carru2.jpg" alt="Haus EQ"/>
                </div>
                <div>
                    <img src="../img/carru3.jpg" alt="Haus EQ"/>
                </div>

            </AutoplaySlider>
    </div>
  )
}
