import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import PropertyDetail from "./PropertyDetail";
import Contact from "./Contact";
import Services from "./Services";

export default function App() {
    
    const [db, setDB] = useState([]);

    useEffect(()=>{
    async function getData() {
        const res = await fetch("https://haus-manager.onrender.com/api/posts");
        const data = await res.json();
        console.log(data);
        setDB(data);
    }

    getData();
    
    }, []);

    return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home db={db} />} />
      <Route path="/nosotros" element={<About db={db} />} />
      <Route path="/servicios" element={<Services db={db} />} />
      <Route path="/contacto" element={<Contact db={db} />} />
      <Route path="propiedades/:propertyId" element={<PropertyDetail db={db}/>} />

      <Route
      path="*"
      element={
        <main>
          <p>Dirección equivocada!</p>
        </main>
      }
    ></Route>
    </Routes>
  </BrowserRouter>
}