import { Link, NavLink } from "react-router-dom";
import "../App.scss"
import "../villa.nicepage.io_nicepage.css_version=e0dfc5a7-98ff-49fe-8041-a271038b1d79.css"
import 'react-dom/test-utils';

export default function Header (){


    return (
        <div>

<nav className="main-nav">
                <Link to="/">
                    <img src="../img/logo.jpg" alt="Hause EQ" />
                </Link>

<NavLink 
    to="/" className={({ isActive }) => (isActive ? 'main-nav__link_unselected' : 'main-nav__link')}>
        Inicio
</NavLink>

<NavLink 
    to="/nosotros" className={({ isActive }) => (isActive ? 'main-nav__link_unselected' : 'main-nav__link')}>
        Nosotros
</NavLink>



<NavLink 
    to="/servicios" className={({ isActive }) => (isActive ? 'main-nav__link_unselected' : 'main-nav__link')}>
        Servicios
</NavLink>



<NavLink 
    to="/contacto" className={({ isActive }) => (isActive ? 'main-nav__link_unselected' : 'main-nav__link')}>
        Contacto
</NavLink>
            </nav>
        </div>
    )
}