import HeadBar from "./HeadBar";
import Header from "./Header";
import Footer from "./Footer";

export default function Contact() {
  return (
    <div>
            <HeadBar />
            <Header />
            <div className="u-body">
    <section className="head-bck -lg -sm -xl -xs u-clearfix u-image u-shading u-section-1" src="" id="sec-aad8">
      <div className="u-clearfix u-sheet u-sheet-1 u-sheet-1-header">
        <div className="u-align-left-xs u-align-right-lg u-align-right-md u-align-right-sm u-align-right-xl u-container-style u-group u-group-1">
          <div className="u-container-layout u-valign-bottom u-container-layout-1">
            <h1 className="u-text u-title u-text-1">CONTACTO</h1>
            <p className="u-large-text u-text u-text-variant u-text-2">Contamos con una experiencia de más de 9 años, dentro de los cuales hemos demostrado
profesionalismo, disciplina, organización y seriedad.</p>
          </div>
        </div>
      </div>
    </section>
    <section className="u-clearfix u-section-2 about-cont1" id="sec-1b1d">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-container-style u-group u-group-1">
          <div className="u-container-layout u-valign-middle u-container-layout-1">
            <div className="mini-gallery">
            <div className="mini-gallery-item">
                <img src="https://images03.nicepage.com/c461c07a441a5d220e8feb1a/50ac257d00fc5a65a23a6402/1.jpg" alt="" />
              </div>
              <div className="mini-gallery-item">
                <img src="https://images03.nicepage.com/c461c07a441a5d220e8feb1a/ecae0d40d27a54499f983d9a/2.jpg" alt="" />
              </div>
              <div className="mini-gallery-item">
                <img src="https://images03.nicepage.com/c461c07a441a5d220e8feb1a/141ac024456b585dbfd32f82/Untitled1.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="u-clearfix u-section-3" id="carousel_dcaf">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-container-style u-group u-group-1">
          
        </div>
        <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-left u-container-style u-image u-layout-cell u-left-cell u-size-30 u-image-1">
              <iframe title="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1994.899055230527!2d-78.48198846384014!3d-0.18236569386552384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d59a875b6644d1%3A0x59b109aae61e6382!2sEdificio%20Smerald!5e0!3m2!1ses-419!2sus!4v1670599325942!5m2!1ses-419!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <div className="u-align-left u-container-style u-layout-cell u-palette-5-dark-3 u-right-cell u-size-30 u-layout-cell-2">
                <div className="u-container-layout u-valign-middle u-container-layout-3">
                  <h2 className="u-text u-text-palette-3-base u-text-2">Quito - Ecuador</h2>
                  <p className="u-text u-text-palette-3-base u-text-3">Av. De los Shyris N34-238 Ed. Smerald of 403 </p>


                  <p className="u-text u-text-palette-3-base u-text-3">+593983115804</p>
                  <p className="u-text u-text-palette-3-base u-text-3">+593991017461</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    

    <section className="u-clearfix u-grey-5 u-section-7" id="sec-4ed6">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-left u-container-style u-expanded-width u-group u-group-1">
          <div className="u-container-layout u-valign-middle-xl u-valign-middle-xs u-container-layout-1">
            <h2 className="u-text u-text-1">Encuentranos en nuestras redes sociales:</h2>
          </div>
        </div>
      </div>
    </section>
  
</div>
            <Footer />
        </div>
  )
}
