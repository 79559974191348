import { useParams } from "react-router-dom";
import PropertyBody from "./PropertyBody";
import { useEffect, useState } from "react";

import GoogleMapReact from 'google-map-react';

export default function PropertyGallery(props) {
  const AnyReactComponent = ({ text }) => <div><img width={30} alt="" src="../img/logosq.jpg" /></div>;

  const [mapCenter, setMapCenter] = useState(16);

  const db = props.db;
  let aptimgs = [];
  let params = useParams();
  console.log(params.propertyId);
  let apartment = db.find((depto) => depto._id === params.propertyId);
  console.log(apartment);

    function getImgs(){
      // Regular expression pattern to match the URLs
      const urlPattern = /src="([^"]*)"/g;

      // Extract the URLs using the pattern
      const st = String(apartment.gallery);

      const urls = st.split(",");
      /*let match;
      while ((match = urlPattern.exec(apartment.gallery)) !== null) {
        urls.push(match[1]);
      }*/

      console.log(urls);
      urls.map((img, indx)=> aptimgs.push({ src: img, thumbnail: img, thumbnailWidth: 300, thumbnailHeight: 200 }));
      console.log(aptimgs);
      return aptimgs;
    }

    useEffect(() => {
      handleAddressChange(apartment.address);
    }, [apartment]);

    const handleAddressChange = async (address) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=AIzaSyBgew6_aDI861EYGMAtZj2SfJknVrDy5dA`
        );
  
        if (!response.ok) {
          // Handle error here
          console.error("Error fetching geocoding data");
          return;
        }
  
        const data = await response.json();
        if (data.status === "OK") {
          const location = data.results[0].geometry.location;
          setMapCenter({ lat: location.lat, lng: location.lng });
        } else {
          // Handle error here
          console.error("Geocoding failed");
        }
      } catch (error) {
        // Handle error here
        console.error("Error while fetching geocoding data", error);
      }
    };

    return(
        <div className="property-body">
            {apartment && <PropertyBody cont={apartment} images={getImgs()} />}

            {apartment && <div>
              <div style={{ height: '500px', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "AIzaSyBgew6_aDI861EYGMAtZj2SfJknVrDy5dA" }}
                  center={mapCenter} // Use the updated map center
                  defaultZoom={16}
                >
                  <AnyReactComponent
                    lat={mapCenter.lat}
                    lng={mapCenter.lng}
                    text="My Marker"
                  />
                </GoogleMapReact>
              </div>
              </div>}
        </div>
    );
}