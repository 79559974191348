import HeadBar from "./HeadBar";
import Header from "./Header";
import Footer from "./Footer";

export default function Services() {
  return (
    <div>
            <HeadBar />
            <Header />
            <div className="u-body">
    <section className="head-bck -lg -sm -xl -xs u-clearfix u-image u-shading u-section-1" src="" id="sec-aad8">
      <div className="u-clearfix u-sheet u-sheet-1 u-sheet-1-header">
        <div className="u-align-left-xs u-align-right-lg u-align-right-md u-align-right-sm u-align-right-xl u-container-style u-group u-group-1">
          <div className="u-container-layout u-valign-bottom u-container-layout-1">
            <h1 className="u-text u-title u-text-1">Servicios</h1>
            <p className="u-large-text u-text u-text-variant u-text-2">Nos tomamos en serio el cumplimiento de
los requisitos de los clientes más exigentes y ofrecemos un servicio de primera
clase. </p>
          </div>
        </div>
      </div>
    </section>
    <section className="u-clearfix u-section-2 about-cont1" id="sec-1b1d">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-container-style u-group u-group-1">
          <div className="u-container-layout u-valign-middle u-container-layout-1">
            <div className="mini-gallery">
            <div className="mini-gallery-item">
                <img src="https://images03.nicepage.com/c461c07a441a5d220e8feb1a/50ac257d00fc5a65a23a6402/1.jpg" alt="" />
              </div>
              <div className="mini-gallery-item">
                <img src="https://images03.nicepage.com/c461c07a441a5d220e8feb1a/ecae0d40d27a54499f983d9a/2.jpg" alt="" />
              </div>
              <div className="mini-gallery-item">
                <img src="https://images03.nicepage.com/c461c07a441a5d220e8feb1a/141ac024456b585dbfd32f82/Untitled1.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="u-clearfix u-section-3" id="carousel_dcaf">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-container-style u-group u-group-1">
          
        </div>
        <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-left u-container-style u-image u-layout-cell u-left-cell u-size-30 u-image-1">
                <div className="u-container-layout" src=""></div>
              </div>
              <div className="u-align-left u-container-style u-layout-cell u-palette-5-dark-3 u-right-cell u-size-30 u-layout-cell-2">
                <div className="u-container-layout u-valign-middle u-container-layout-3">
                  <h2 className="u-text u-text-palette-3-base u-text-2">Haus EQ Real Estate S.A.S</h2>
                  <p className="u-text u-text-palette-3-base u-text-3">Dentro de la amplia gama de servicios inmobiliarios, contamos con:
<ul>
  <li>Compra</li>
  <li>Venta</li>
  <li>Arriendos</li>
  <li>Administración</li>
  <li>Permuta</li>
  <li>Asesoría legal</li>
  <li>Análisis financieros</li>
  <li>Avalúos</li>
  <li>Desarrollo de proyectos</li>
  








</ul>

</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    

    <section className="u-clearfix u-grey-5 u-section-7" id="sec-4ed6">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-left u-container-style u-expanded-width u-group u-group-1">
          <div className="u-container-layout u-valign-middle-xl u-valign-middle-xs u-container-layout-1">
            <h2 className="u-text u-text-1">Valoramos y cultivamos nuestras capacidades profesionales, siempre teniendo como
referentes la competencia, exclusividad y pasión.</h2>
          </div>
        </div>
      </div>
    </section>
  
</div>
            <Footer />
        </div>
  )
}
